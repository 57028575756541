import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import api, {IdentificationRequestStatus} from '../service/api'
import {useI18n} from '../i18n'

export const IdentificationScreen = () => {
    const navigate = useNavigate()
    const [info, setInfo] = useState('')
    const [url, setUrl] = useState('')
    const {t} = useI18n()

    useEffect(() => {
        api.getRequestStatus().then(s => {
            if (s.open) {
                setInfo(s.info || '')
                setUrl((s as IdentificationRequestStatus).url)
            } else {
                navigate('/notopen')
            }
        })
    }, [navigate])

    return (
        <div>
            <h2>{t('IdentificationScreen.head')}</h2>
            <p>{info}</p>
            <hr/>
            <p>{t('IdentificationScreen.copy')}</p>
            <a href={url} className="btn btn-primary btn-lg">{t('IdentificationScreen.start')}</a>
        </div>
    )
}
export const IdentificationDoneScreen = () => {
    const {t} = useI18n()
    return (
        <>
            <h2>{t('IdentificationScreen.thankYou')}</h2>
        </>
    )
}

export const IdentifiedScreen = () => {
    const {t} = useI18n()
    return (
        <div>
            <h2>{t('IdentifiedScreen.message')}</h2>
        </div>
    )
}

export const IdentificationPendingScreen = () => {
    const {t} = useI18n()
    return (
        <div>
            <h2>{t('IdentificationPendingScreen.message')}</h2>
        </div>
    )
}

export const IdentificationErrorScreen = () => {
    const {t} = useI18n()
    return (
        <div>
            <h2>{t('IdentificationErrorScreen.message')}</h2>
        </div>
    )
}

