import React, {forwardRef} from 'react'
import {Button, ButtonProps, Form} from 'react-bootstrap'
import {Controller, FormProvider, useFormContext} from 'react-hook-form'
import get from 'lodash/get'
import {useI18n} from './i18n'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export const MyForm = ({onSubmit, children, methods}: any) => {
    return (
        <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
                {children}
            </FormProvider>
        </Form>
    )
}
export const FormGroup = ({children}: any) => <Form.Group className="mb-2">{children}</Form.Group>
export const FormField = ({name, label, checkBoxLabel, type, rules, ...props}: any) => {
    const {t} = useI18n()
    const {control, formState: {errors, touchedFields}} = useFormContext()
    const e = get(errors, name)
    const config = e && get(rules, e.type as string)
    const FF = type || Form.Control
    return (
        <>
            <Form.Label>{t(label || name)}</Form.Label>
            <Controller render={({field}) =>
                <FF {...field} label={checkBoxLabel}/>
            } defaultValue={(type === CheckBox ? false : '')} isInvalid={!!get(errors, name)}
                        isValid={type === CheckBox ? undefined : touchedFields[name] && !get(errors, name)} name={name}
                        control={control} rules={rules} label={checkBoxLabel} {...props} />
            {e &&
                <Form.Control.Feedback type="invalid">
                    {!!e.message || t('validation.' + e.type, {label, config})}
                </Form.Control.Feedback>
            }
        </>
    )
}
export const CheckBox = forwardRef(({name, value, onChange, ...props}: any, ref) =>
    <Form.Check checked={value} id={`__checkbox-id-${name}`} onChange={(e: any) => onChange(e.target.checked)}
                ref={ref} {...props} />)
export const SubmitButton = ({children, ...rest}: ButtonProps & any) => {
    return <Button {...rest} className="d-flex align-items-center"><FontAwesomeIcon size="2x" icon="check-circle"/>
        <span className="ms-3">{children}</span></Button>
}
