import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import api, {BankData, BankRequestStatus, ValidationError} from '../service/api'
import {keys} from '../utils'
import {CheckBox, FormField, FormGroup, MyForm, SubmitButton} from '../forms'
import {isValidBIC, isValidIBAN} from 'ibantools'
import {useI18n} from '../i18n'

const NON_EURO = ['DK', 'BG', 'HR', 'CZ', 'HU', 'PL', 'RO', 'SE', 'NO', 'IS', 'LI']
export const BankDataScreen = () => {
    const { t } = useI18n()
    const [info, setInfo] = useState('')
    const navigate = useNavigate()

    const methods = useForm<BankData & { acceptEuro: boolean }>({
        mode: 'all',
        defaultValues: {
            iban: '',
            bic: '',
            bank: ''
        },
    })

    const {reset, formState, setError, clearErrors, watch} = methods

    const iban = watch('iban')
    const acceptEuro = watch('acceptEuro')

    const [showEuroWarning, setShowEuroWarning] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)

    // test ibans: DK5000400440116243 DE12500105170648489890
    useEffect(() => {
        setShowEuroWarning(!!iban && NON_EURO.some(s => iban.startsWith(s)))
    }, [iban])

    useEffect(() => {
        api.getRequestStatus().then(s => {
            if (s.open) {
                setInfo(s.info || '')
                const bankData = (s as BankRequestStatus).bankData
                setIsUpdate(!!bankData?.iban)
                reset(bankData)
            } else {
                navigate('/notopen')
            }
        })
    }, [navigate, reset])

    const onSubmit = useCallback((data: any) => {
        clearErrors()
        if (!info || window.confirm(info)) {
            api.updateBankData(data).then(() => navigate('/bankdata/done')).catch((v: ValidationError<BankData>) => {
                keys(v.data).forEach(k => setError(k, {type: 'server', message: v.data[k]}))
            })
        }
    }, [clearErrors, info, navigate, setError])
    return (
        <div>
            <h2>{t('BankDataScreen.head.' + (isUpdate ? 'update' : 'create'))}</h2>
            <p>{info}</p>
            <MyForm onSubmit={onSubmit} methods={methods}>
                <FormGroup>
                    <FormField name="iban"
                               rules={{required: true, validate: {iban: (v: string) => isValidIBAN(v)}}}/>
                </FormGroup>
                <FormGroup>
                    <FormField name="bic"
                               rules={{required: true, validate: {bic: (v: string) => isValidBIC(v)}}}/>
                </FormGroup>
                <FormGroup>
                    <FormField name="bank" rules={{required: true, minLength: 5}}/>
                </FormGroup>
                {showEuroWarning && <FormGroup>
                    <FormField type={CheckBox} name="acceptEuro"
                               rules={{validate: (v: boolean) => (!showEuroWarning || v) || ' '}}
                               checkBoxLabel={<i>{t('BankDataScreen.euroWarning')}</i>}/>
                </FormGroup>}
                <div className="mt-5 pb-4">
                    <SubmitButton disabled={(showEuroWarning || !acceptEuro) && !formState.isValid} type="submit">{t('BankDataScreen.submit')}</SubmitButton>
                </div>
            </MyForm>
        </div>
    )
}
export const BankDataDoneScreen = () => {
    const { t } = useI18n()
    return (
        <>
            <h2>{t('BankDataScreen.thankYou')}</h2>
        </>
    )
}
