export interface Option {
    value: any
    text: string
}

export const cleanObject = (o: any) => {
    Object.keys(o).forEach(k => o[k] === null && delete o[k])
    return o
}

export const convertBase64ImageToBlob = (image: string): Blob => {
    const parts = image.split(';base64,')
    const mimeType = parts[0].split(':')[1]
    const bytes = Uint8Array.from(
        atob(parts[1])
            .split('')
            .map(char => char.charCodeAt(0))
    )
    return new Blob([bytes], { type: mimeType })
}

export function keys<O>(o: O) {
    return Object.keys(o as any) as (keyof O)[];
}
