import React, {useCallback, useEffect, useRef, useState} from 'react'
import SignatureCanvas from 'react-signature-canvas'
import {useNavigate} from 'react-router-dom'
import api from '../service/api'
import {convertBase64ImageToBlob} from '../utils'
import * as pdfobject from 'pdfobject'
import {Form} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {PDFObject} from 'react-pdfobject'
import {FormGroup, SubmitButton} from '../forms'
import {useI18n} from '../i18n'

const PdfViewer = ({pdf}: any) => {
    const {t} = useI18n()

    return <PDFObject url={pdf} containerId="pdfHolder"
               fallbackLink={`<em>${t('PdfViewer.fallback.message')}</em><br/><a href='[url]' class='btn btn-primary mt-2' target='_blank'>${t('PdfViewer.fallback.button')}</a>`}/>
}

export const SignScreen = () => {
    const {t} = useI18n()
    const ref = useRef<SignatureCanvas | null>()
    const [pdf, setPdf] = useState<string | null>()
    const [location, setLocation] = useState<string | null>()
    const [info, setInfo] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        api.getRequestStatus().then(s => {
            if (s.open) {
                setInfo(s.info || '')
            } else {
                navigate('/notopen')
            }
        })
    }, [navigate])

    useEffect(() => {
        !pdf && api.getPdf().then(r => setPdf(r))
    }, [pdf])

    const submit = useCallback(() => {
        const canvas = ref.current!.getTrimmedCanvas()
        if (!!location && canvas.width > 50 && canvas.height > 50) {
            const blob = convertBase64ImageToBlob(canvas.toDataURL('image/png'))
            api.sendSignature(location!, blob).then(() => navigate('/sign/done'))
        } else {
            alert(t('SignScreen.validationErrorMessage'))
        }
    }, [location, navigate, t])

    const clearSignature = useCallback((e: any) => {
        e.preventDefault()
        ref.current!.clear()
    }, [])
    return (
        <>
            <p>{info}</p>
            {!!pdf && <div className="signatureBox"
                           style={{'height': !pdfobject.supportsPDFs ? 'auto' : window.innerHeight * 0.6}}>
                <PdfViewer pdf={pdf}/>
            </div>}
            <div className="row ms-md-4 me-md-2">
                <div className="col-md-3">
                    <FormGroup>
                        <Form.Label>{t('SignScreen.place')}</Form.Label>
                        <Form.Control onChange={e => setLocation(e.target.value)}/>
                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <FormGroup>
                        <Form.Label>{t('SignScreen.signature')}</Form.Label>
                        <div className="signature">
                            <SignatureCanvas ref={r => ref.current = r}
                                             canvasProps={{width: 500, height: 160}}></SignatureCanvas>
                            <FontAwesomeIcon style={{position: 'absolute', right: '15px', bottom: '20px'}} size="2x"
                                             color="#ccc" icon="times-circle" onClick={clearSignature}/>
                        </div>
                    </FormGroup>
                </div>
                <div className="col-md-3 mt-md-auto mb-3">
                    <SubmitButton onClick={submit}>{t('SignScreen.submit')}</SubmitButton>
                </div>
            </div>
        </>
    )
}
export const SignDoneScreen = () => {
    const {t} = useI18n()
    const [pdf, setPdf] = useState<string | null>()
    useEffect(() => {
        !pdf && api.getSignedPdf().then(r => setPdf(r))
    }, [pdf])
    return (
        <>
            <h2>{t('SignScreen.thankYou')}</h2>
            {!!pdf && <div className="signatureBox" style={{'height': window.innerHeight}}>
                <PdfViewer pdf={pdf}/>
            </div>}
        </>
    )
}
