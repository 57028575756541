import React, {Suspense} from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import {library} from '@fortawesome/fontawesome-svg-core'
import {faAddressCard, faArrowCircleRight, faArrowRight, faBan, faCalendar, faCheck, faCheckCircle, faCopy, faCrosshairs, faDirections, faEdit, faEnvelope, faFileImage, faFilter, faLongArrowAltLeft, faLongArrowAltRight, faMapMarkerAlt, faPhone, faPlusCircle, faRedo, faRetweet, faSearch, faSearchMinus, faSearchPlus, faSignOutAlt, faStar, faSync, faThumbsDown, faThumbsUp, faTimes, faTimesCircle, faTrashAlt, faUndo,} from '@fortawesome/free-solid-svg-icons'

import {registerLocale, setDefaultLocale} from 'react-datepicker';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import pt from 'date-fns/locale/pt';

library.add(faUndo, faRedo, faFileImage, faCalendar, faPlusCircle, faCheck, faCheckCircle, faSignOutAlt, faTimesCircle, faTrashAlt, faEdit, faSearch, faSearchMinus, faSearchPlus, faLongArrowAltRight, faLongArrowAltLeft, faArrowCircleRight, faRetweet, faBan, faStar, faPhone, faMapMarkerAlt, faSync, faCopy, faArrowRight, faThumbsUp, faThumbsDown, faCrosshairs, faDirections, faFilter, faEnvelope, faAddressCard, faTimes, )

registerLocale('de', de)
registerLocale('en', en)
registerLocale('es', es)
registerLocale('fr', fr)
registerLocale('it', it)
registerLocale('pt', pt)

setDefaultLocale('de')

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Suspense fallback={<div>Loading...</div>}><App /></Suspense>);
