import * as React from 'react';
import {createContext, useContext, useEffect, useState} from 'react';
import api from './api';
import jwt_decode from 'jwt-decode'

export interface Principal {
    id: number
    email: string
    firstname: string
    lastname: string
}

export interface Token {
    accessToken: string
    exp: string
}

interface AppContextProps {
    isAuthenticated?: boolean
    principal: Principal | null
    signIn: (jwt: string) => void
    signOut: () => void
}

export const AppContext = createContext<Partial<AppContextProps>>({})
export const useAppContext = () => useContext(AppContext)


export default function AppContextProvider({children}: any) {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined)
    const [principal, setPrincipal] = useState<Principal | null>(null)

    api.onAuthFailure = () => {
        authContext.signOut()
    }

    const setToken = async (userToken: Token | null) => {
        let u: Principal | null = null
        if (userToken) {
            api.setToken(userToken.accessToken)
            localStorage.setItem("userToken", JSON.stringify(userToken))
            u = await api.me()
            if (u) {
                setPrincipal(u)
                setIsAuthenticated(true)
            }
        }
        if (!u) {
            api.setToken(null)
            localStorage.removeItem("userToken")
            setIsAuthenticated(false)
            setPrincipal(null)
        }
    }

    const getTokenFromJwt = (t: string) => {
        const jwt: any = jwt_decode(t)
        const userToken: Token = {
            accessToken: t,
            exp: new Date(jwt.exp * 1000).toISOString()
        }
        return userToken
    }

    const getStoredToken = (): Token | null => {
        try {
            const tokenJson = localStorage.getItem('userToken')
            const t: Token = tokenJson && JSON.parse(tokenJson)
            return t?.accessToken ? t : null
        } catch {
            return null
        }
    }


    useEffect(() => {
        const restore =  async () => {
            let userToken = getStoredToken()
            if (userToken) {
                const exp = new Date(userToken.exp).getTime() - new Date().getTime()
                if (exp < 30 * 60 * 1000) {
                    console.log("Token older than 30 minutes, refreshing...")
                    api.setToken(userToken.accessToken)
                    const r = await api.refresh()
                    await setToken(getTokenFromJwt(r))
                } else if (exp <= 0) {
                    console.log("Token expired")
                    await setToken(null)
                } else {
                    await setToken(userToken)
                }
            } else {
                await setToken(null)
            }
        }
        restore()
    }, [])

    const authContext = React.useMemo(
        () => ({
            principal: principal,
            isAuthenticated: isAuthenticated,
            signIn: (jwt: string) => {
                setToken(getTokenFromJwt(jwt))
            },
            signOut: () => {
                if (isAuthenticated) {
                    setToken(null)
                }
            },
        }),
        [principal, isAuthenticated]
    );

    return (
        <AppContext.Provider value={authContext}>
            {children}
            </AppContext.Provider>
    )
}
