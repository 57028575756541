import React, {JSX, useEffect} from 'react';
import AppContextProvider, {useAppContext} from './service/app-context';
import {BrowserRouter as Router, Link, Navigate, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {Navbar, NavDropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logo from './assets/onlogist-logo.svg'
import rhdLogo from './assets/rhd-logo.jpg'
import {SignDoneScreen, SignScreen} from './screens/signing'
import {BankDataDoneScreen, BankDataScreen} from './screens/bankdata'
import {IdentificationDoneScreen, IdentificationErrorScreen, IdentificationPendingScreen, IdentificationScreen, IdentifiedScreen} from './screens/identification'
import {Impressum} from './screens/impressum'
import I18nContextProvider, {useI18n} from './i18n'


const ProtectedRoute = ({ children }: {children: JSX.Element}) => {
    const { isAuthenticated } = useAppContext()
    if (isAuthenticated === undefined) {
        // no ready? no route at all...
        return <></>
    } else if (isAuthenticated) {
        return children
    } else {
        return <Navigate  to="/login" replace />;
    }
};

const Login = () => {
    const {t} = useI18n()
    return (
        <h2>{t('LoginScreen.message')}</h2>
    )
}

const NotOpenScreen = () => {
    const {t} = useI18n()
    return (
        <div>
            <h2>{t('NotOpenScreen.message')}</h2>
        </div>
    )
}

const StartScreen = () => {
    const {jwt, action} = useParams()
    const {isAuthenticated, signIn} = useAppContext()
    const navigate = useNavigate()
    const {t} = useI18n()
    useEffect(() => {
        if (jwt) {
            signIn!(jwt)
        }
    }, [jwt, signIn])

    useEffect(() => {
        if (isAuthenticated && action) {
            navigate(`/${action}`)
        } else if (isAuthenticated === false) {
            navigate('/login')
        }
    }, [isAuthenticated, navigate, action])
    return (
        <h2>{t('pleaseWait')}</h2>
    )
}

const Navigation = () => {
    const {locale, setLocale, availableLocales, t} = useI18n()
    return (<Navbar bg="light">
        <Navbar.Brand>
            <img src={logo} alt="ONLOGIST.com" />
            <img className={'d-none d-md-inline'} style={{marginLeft:'20px'}} src={rhdLogo} height={32} alt="RHD-Logo" />
        </Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
            {availableLocales?.length > 1 && <NavDropdown title={t('Locales.head')}>
                {availableLocales.map(l =>
                    <NavDropdown.Item key={l} onClick={() => setLocale(l)}>{t('Locales.' + l)} {locale === l && <FontAwesomeIcon icon="check"></FontAwesomeIcon>}</NavDropdown.Item>
                )}
            </NavDropdown>}
        </Navbar.Collapse>
    </Navbar>)
}

const Footer = () => {
    const {t} = useI18n()
    return (
        <footer className="container">
            <hr/>
            <p className="float-right">
                <Link to="/impressum">{t('Navigation.impressum')}</Link>&nbsp;·&nbsp;
                <a href="https://portal.onlogist.com/privacy" target="_blank" rel="noopener noreferrer">{t('Navigation.privacy')}</a>
            </p>
            <p>{t('Navigation.copyright')}</p>
        </footer>
    )
}

function App() {
  return (
      <Router>
          <AppContextProvider>
              <I18nContextProvider>
                  <div id="app">
                      <div className="overlay-black"></div>
                      <header className="header fixed-top bg-light">
                          <div className="container">
                              <Navigation/>
                          </div>
                      </header>
                      <section>
                          <div className="container" id="mainContent">
                              <div className="pageWrap">
                                  <div className="form justify-content-center">
                                      <Routes>
                                          <Route path="/login" element={<Login />} />
                                          <Route path="/impressum" element={<Impressum />} />
                                          <Route path="/start/:action/:jwt" element={<StartScreen />} />
                                          <Route path='/sign' element={<ProtectedRoute><SignScreen /></ProtectedRoute>} />
                                          <Route path='/sign/done' element={<ProtectedRoute><SignDoneScreen /></ProtectedRoute>} />
                                          <Route path='/bankdata' element={<ProtectedRoute><BankDataScreen /></ProtectedRoute>} />
                                          <Route path='/bankdata/done' element={<ProtectedRoute><BankDataDoneScreen /></ProtectedRoute>} />
                                          <Route path='/identification' element={<ProtectedRoute><IdentificationScreen /></ProtectedRoute>} />
                                          <Route path='/identification/done' element={<ProtectedRoute><IdentificationDoneScreen /></ProtectedRoute>} />
                                          <Route path='/identification/identified' element={<IdentifiedScreen />} />
                                          <Route path='/identification/pending' element={<IdentificationPendingScreen />} />
                                          <Route path='/identification/error' element={<IdentificationErrorScreen />} />
                                          <Route path='/notopen' element={<ProtectedRoute><NotOpenScreen /></ProtectedRoute>} />
                                          <Route path="/" element={<Navigate to="/login" replace />} />
                                          <Route path="*" element={<Navigate to="/" replace />} />
                                      </Routes>
                                  </div>
                              </div>
                              <Footer />
                          </div>
                      </section>
                  </div>
              </I18nContextProvider>
          </AppContextProvider>
      </Router>
  );
}

export default App;
