import React from 'react'
import {useI18n} from '../i18n'

export const Impressum = () => {
    const {t} = useI18n()
    return (<div>
        <h1>{t('Impressum.head')}</h1>
        <div dangerouslySetInnerHTML={{__html: t('Impressum.copy')}} />
    </div>)
}
