import * as React from 'react';
import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import Polyglot from 'node-polyglot';
import {useSearchParams} from 'react-router-dom'
import {setDefaultLocale} from 'react-datepicker'

interface I18nContextProps {
    locale: string
    availableLocales: string[]
    setLocale: (locale: string) => void
    t: (phrase: string, options?: number | Polyglot.InterpolationOptions) => string
}

export const I18nContext = createContext<I18nContextProps>({
    availableLocales: ['de'], locale: 'de', setLocale(_: string): void {
    }, t(_: string, __: number | Polyglot.InterpolationOptions | undefined): string {
        return ''
    },
})

export const useI18n = () => useContext(I18nContext)

const availableLocales = ['de', 'en', 'es', 'fr', 'it', 'pt']

const i18n = new Polyglot({
    phrases: availableLocales.reduce((phrases, l) => ({...phrases, [l]: require(`./${l}.json`)}), {})
})

export default function I18nContextProvider({children}: any) {
    const [searchParams, setSearchParams] = useSearchParams()

    const [locale, setLocale] = useState<string>(localStorage.getItem('language') || 'de')

    const t = useCallback((phrase: string, options?: any) => {
        return i18n?.t(`${locale}.${phrase}`, options) || ''
    }, [locale])

    useEffect(() => {
        if (!!searchParams?.get('lang')) {
            setLocale(searchParams.get('lang')!)
            searchParams.delete('lang')
            setSearchParams(searchParams)
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        setDefaultLocale(locale)
        localStorage.setItem('language', locale)
    }, [locale])

    const context: I18nContextProps = useMemo(() => ({
        locale,
        setLocale,
        availableLocales,
        t,
    }), [locale, t])

    if (i18n) {
        return (
            <I18nContext.Provider value={context}>{children}</I18nContext.Provider>
        )
    } else {
        return null
    }
}
