import axios, {AxiosInstance, Method} from 'axios';

export type Errors<T> = {[key in keyof T ]: string}

export class ValidationError<T> extends Error {
    data: Errors<T>

    constructor(data: Errors<T>) {
        super()
        this.data = data
    }
}


class Api {
    public onAuthFailure: Function | null = null

    public token: string | null = null

    public baseUrl = process.env.REACT_APP_API_BASE_URL

    private instance: AxiosInstance

    constructor() {
        this.instance = axios.create({
            baseURL: this.baseUrl,
            timeout: 30000,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Accept-Language": "de-DE",
            }
        })
        this.instance.interceptors.response.use(r => r, err => {
            if (err.response?.status === 401) {
                this.forbidden()
            } else if (err.response?.status === 400) {
                throw new ValidationError(err.response.data)
            } else if (err.message?.includes("timeout")) {
                alert("Request failed. Please try again.")
            } else {
                console.log("Unknown API error for " + err.config.url + ": ", err)
            }
            return null
        })
    }

    setToken(t: string | null) {
        if (t) {
            this.token = t
            Object.assign(this.instance.defaults.headers, this.getAuthHeader())
        } else {
            this.token = null
            delete this.instance.defaults.headers["Authorization"]
        }
    }

    getToken() {
        return this.token
    }

    getAuthHeader() {
        return {Authorization: `Bearer ${this.token}`}
    }

    async performRequest(method: Method, url: string, data: any = undefined, headers: any = undefined, responseType: any = undefined) {
        //console.log(method, url)
        const res = await this.instance.request({url, method, data, headers, responseType})
        return res?.data || null
    }

    forbidden() {
        this.token = null
        this.onAuthFailure && this.onAuthFailure()
        return null
    }

    async me() {
        return await this.performRequest('GET', `/me`)
    }

    async refresh() {
        return await this.performRequest('GET', `/refresh`)
    }


    async sendSignature(location: string, blob: Blob) {
        const data = new FormData()
        data.append("signature", blob)
        data.append("info", new Blob([JSON.stringify({location})], {type:'application/json'}))
        return await this.performRequest('POST', '/sign', data, {"Content-Type": "multipart/form-data",})
    }

    async getPdf(): Promise<string> {
        return this.getPdfAsBlob('/pdf')
    }

    async getSignedPdf(): Promise<string> {
        return this.getPdfAsBlob('/signedpdf')
    }

    async getPdfAsBlob(url: string) {
        const blob = await this.performRequest('GET', url, undefined,{'Accept': 'application/pdf'}, 'blob')
        //return URL.createObjectURL(blob)
        return new Promise<string>(resolve => {
            const a = new FileReader();
            a.onload = function(e) {
                resolve(e.target?.result as string)
                // resolve({
                //     url:e.target?.result as string,
                //     blob, blobUrl
                // })
            }
            a.readAsDataURL(blob);
        })
    }

    async updateBankData(bankData: BankData): Promise<any> {
        return this.performRequest('PUT', `/bankdata`, bankData)
    }

    getRequestStatus(): Promise<RequestStatus> {
        return this.performRequest('GET', '/request/status')
    }
}

export interface RequestStatus {
    open: boolean
    info?: string
}

export interface BankRequestStatus extends RequestStatus {
    bankData: BankData
}

export interface IdentificationRequestStatus extends RequestStatus {
    url: string
}

export interface BankData {
    bank: string
    iban: string
    bic: string
}

// export interface BlobResult {
//     url: string
//     blob: Blob,
//     blobUrl: string
//
// }

const api = new Api()

export default api
